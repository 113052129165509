<template>
	<div class="home-other">

		<div class="home-o-title">{{$t('i18nn_ee110e3c7304b62d')}}</div>

		<div class="home-o-subtitle">{{$t('i18nn_f9f51c08f4fadac8')}}</div>
		<div class="home-o-list">
			<ul>
				<el-tooltip class="item" effect="dark" content="C-Lodop下载" placement="top">
					<li>
						<router-link :to="{ name: 'downloadCLodop'}">
							<i style="font-size: 30px;" class="el-icon-download"></i>
							<div>C-Lodop<span>{{$t('i18nn_245883c80f181c4a')}}</span></div>
						</router-link>
					</li>
				</el-tooltip>
			</ul>
		</div>

		<div class="home-o-subtitle">{{$t('i18nn_c0eb5cb77d8024b6')}}</div>
		<div class="home-o-list">
			<ul>
				<!-- <el-tooltip class="item" effect="dark" content="https://www.hytxlogistics.com/resources" placement="top">
					<li>
						<a href="https://www.hytxlogistics.com/resources" target="_blank" title="https://www.hytxlogistics.com/resources">
							<i style="font-size: 30px;" class="el-icon-files"></i>
							<div>资源库(US)</div>
						</a>
					</li>
				</el-tooltip> -->
				<el-tooltip class="item" effect="dark"
					content="https://hytx-oss-wh.oss-cn-hongkong.aliyuncs.com/helpDoc/HYTX_WMS_Admin_HELP_CN.V7.pdf"
					placement="top">
					<li>
						<a href="https://hytx-oss-wh.oss-cn-hongkong.aliyuncs.com/helpDoc/HYTX_WMS_Admin_HELP_CN.V7.pdf"
							target="_blank" title="CN.V7">
							<i style="font-size: 30px;" class="el-icon-question"></i>
							<div><span>{{$t('i18nn_54153ccc0c756e9f')}}</span>(<span>{{$t('9fcdcb3a067903d8')}}</span>)</div>
						</a>
					</li>
				</el-tooltip>
			</ul>
		</div>

		<div class="home-o-subtitle">
			<span>{{$t('i18nn_72917c44e787864a')}}</span>/<span>{{$t('i18nn_55806c9c59b87e47')}}</span>/<span>{{$t('i18nn_3b4e0cc90e264c6f')}}</span>
		</div>
		<div class="home-o-list">
			<ul>
				<el-tooltip class="item" effect="dark" content="https://docs.qq.com/sheet/DWWJTeE5RTkp4ZlpN" placement="top">
					<li>
						<a href="https://docs.qq.com/sheet/DWWJTeE5RTkp4ZlpN" target="_blank"
							title="https://docs.qq.com/sheet/DWWJTeE5RTkp4ZlpN">
							<svg t="1652323572189" class="icon" viewBox="0 0 1024 1024" version="1.1"
								xmlns="http://www.w3.org/2000/svg" p-id="12481" width="200" height="200">
								<path
									d="M192 0h640c35.346 0 64 28.654 64 64v896c0 35.346-28.654 64-64 64H192c-35.346 0-64-28.654-64-64V64c0-35.346 28.654-64 64-64z m112 192c-26.51 0-48 21.49-48 48s21.49 48 48 48h416c26.51 0 48-21.49 48-48s-21.49-48-48-48H304z m0 256c-26.51 0-48 21.49-48 48s21.49 48 48 48h288c26.51 0 48-21.49 48-48s-21.49-48-48-48H304z m0 256c-26.51 0-48 21.49-48 48s21.49 48 48 48h160c26.51 0 48-21.49 48-48s-21.49-48-48-48H304z m383.634 192.826c13.91 0 25.227-11.315 25.227-25.223v-7.936a4.526 4.526 0 0 1 3.108-4.303c29.512-9.772 49.77-34.072 52.872-63.418 3.75-35.829-12.223-56.53-26.286-67.589-15.336-12.153-34.4-18-51.225-23.158l-2.355-0.727c-12.61-3.912-23.399-7.45-27.7-11.137a4.73 4.73 0 0 1-0.467-0.46l-1.483-1.701c-2.488-3.04-3.803-6.639-3.803-10.41 0-10.4 12.944-21.99 31.517-21.99 18.572 0 31.516 11.59 31.516 21.99 0 13.91 11.319 25.225 25.23 25.225 13.91 0 25.228-11.315 25.228-25.224 0-29.925-21.32-57.136-53.051-67.711a4.528 4.528 0 0 1-3.1-4.3v-8.53c0-13.908-11.318-25.224-25.228-25.224-13.91 0-25.23 11.316-25.23 25.224v8.133a4.516 4.516 0 0 1-3.152 4.318c-32.53 10.393-54.387 37.836-54.387 68.29 0 15.92 5.88 31.493 16.563 43.86 0.09 0.104 0.176 0.213 0.251 0.32 0.78 1.093 1.582 1.893 2.694 3l0.79 0.793c0.5 0.501 0.685 0.681 0.894 0.82 0.205 0.137 0.398 0.29 0.575 0.454 12.753 11.841 30.46 17.282 47.588 22.544l1.447 0.447c32.313 9.897 45.328 14.825 42.87 37.674-1.208 13.289-14.242 22.572-31.698 22.572-18.572 0-31.516-11.589-31.516-21.99 0-13.908-11.318-25.224-25.229-25.224-13.912 0-25.229 11.316-25.229 25.223 0 30.453 21.857 57.896 54.387 68.289a4.516 4.516 0 0 1 3.153 4.316v7.54c0 13.908 11.318 25.223 25.23 25.223z"
									p-id="12482" fill="#ffffff"></path>
							</svg>
							<div>{{$t('i18nn_b5f81f005ec90ee8')}}</div>
						</a>
					</li>
				</el-tooltip>
				<el-tooltip class="item" effect="dark" content="https://docs.qq.com/form/page/DWVF0dVVtaEVJVnpY#/fill"
					placement="top">
					<li>
						<a href="https://docs.qq.com/form/page/DWVF0dVVtaEVJVnpY#/fill" target="_blank"
							title="https://docs.qq.com/form/page/DWVF0dVVtaEVJVnpY#/fill">
							<svg t="1652323572189" class="icon" viewBox="0 0 1024 1024" version="1.1"
								xmlns="http://www.w3.org/2000/svg" p-id="12481" width="200" height="200">
								<path
									d="M192 0h640c35.346 0 64 28.654 64 64v896c0 35.346-28.654 64-64 64H192c-35.346 0-64-28.654-64-64V64c0-35.346 28.654-64 64-64z m112 192c-26.51 0-48 21.49-48 48s21.49 48 48 48h416c26.51 0 48-21.49 48-48s-21.49-48-48-48H304z m0 256c-26.51 0-48 21.49-48 48s21.49 48 48 48h288c26.51 0 48-21.49 48-48s-21.49-48-48-48H304z m0 256c-26.51 0-48 21.49-48 48s21.49 48 48 48h160c26.51 0 48-21.49 48-48s-21.49-48-48-48H304z m383.634 192.826c13.91 0 25.227-11.315 25.227-25.223v-7.936a4.526 4.526 0 0 1 3.108-4.303c29.512-9.772 49.77-34.072 52.872-63.418 3.75-35.829-12.223-56.53-26.286-67.589-15.336-12.153-34.4-18-51.225-23.158l-2.355-0.727c-12.61-3.912-23.399-7.45-27.7-11.137a4.73 4.73 0 0 1-0.467-0.46l-1.483-1.701c-2.488-3.04-3.803-6.639-3.803-10.41 0-10.4 12.944-21.99 31.517-21.99 18.572 0 31.516 11.59 31.516 21.99 0 13.91 11.319 25.225 25.23 25.225 13.91 0 25.228-11.315 25.228-25.224 0-29.925-21.32-57.136-53.051-67.711a4.528 4.528 0 0 1-3.1-4.3v-8.53c0-13.908-11.318-25.224-25.228-25.224-13.91 0-25.23 11.316-25.23 25.224v8.133a4.516 4.516 0 0 1-3.152 4.318c-32.53 10.393-54.387 37.836-54.387 68.29 0 15.92 5.88 31.493 16.563 43.86 0.09 0.104 0.176 0.213 0.251 0.32 0.78 1.093 1.582 1.893 2.694 3l0.79 0.793c0.5 0.501 0.685 0.681 0.894 0.82 0.205 0.137 0.398 0.29 0.575 0.454 12.753 11.841 30.46 17.282 47.588 22.544l1.447 0.447c32.313 9.897 45.328 14.825 42.87 37.674-1.208 13.289-14.242 22.572-31.698 22.572-18.572 0-31.516-11.589-31.516-21.99 0-13.908-11.318-25.224-25.229-25.224-13.912 0-25.229 11.316-25.229 25.223 0 30.453 21.857 57.896 54.387 68.289a4.516 4.516 0 0 1 3.153 4.316v7.54c0 13.908 11.318 25.223 25.23 25.223z"
									p-id="12482" fill="#ffffff"></path>
							</svg>
							<div>{{$t('i18nn_d8f295864833fc57')}}</div>
						</a>
					</li>
				</el-tooltip>
				<el-tooltip class="item" effect="dark" content="https://docs.qq.com/form/page/DWUxnanR5RnRRZXZV#/fill"
					placement="top">
					<li>
						<a href="https://docs.qq.com/form/page/DWUxnanR5RnRRZXZV#/fill" target="_blank"
							title="https://docs.qq.com/form/page/DWUxnanR5RnRRZXZV#/fill">
							<svg t="1652323572189" class="icon" viewBox="0 0 1024 1024" version="1.1"
								xmlns="http://www.w3.org/2000/svg" p-id="12481" width="200" height="200">
								<path
									d="M192 0h640c35.346 0 64 28.654 64 64v896c0 35.346-28.654 64-64 64H192c-35.346 0-64-28.654-64-64V64c0-35.346 28.654-64 64-64z m112 192c-26.51 0-48 21.49-48 48s21.49 48 48 48h416c26.51 0 48-21.49 48-48s-21.49-48-48-48H304z m0 256c-26.51 0-48 21.49-48 48s21.49 48 48 48h288c26.51 0 48-21.49 48-48s-21.49-48-48-48H304z m0 256c-26.51 0-48 21.49-48 48s21.49 48 48 48h160c26.51 0 48-21.49 48-48s-21.49-48-48-48H304z m383.634 192.826c13.91 0 25.227-11.315 25.227-25.223v-7.936a4.526 4.526 0 0 1 3.108-4.303c29.512-9.772 49.77-34.072 52.872-63.418 3.75-35.829-12.223-56.53-26.286-67.589-15.336-12.153-34.4-18-51.225-23.158l-2.355-0.727c-12.61-3.912-23.399-7.45-27.7-11.137a4.73 4.73 0 0 1-0.467-0.46l-1.483-1.701c-2.488-3.04-3.803-6.639-3.803-10.41 0-10.4 12.944-21.99 31.517-21.99 18.572 0 31.516 11.59 31.516 21.99 0 13.91 11.319 25.225 25.23 25.225 13.91 0 25.228-11.315 25.228-25.224 0-29.925-21.32-57.136-53.051-67.711a4.528 4.528 0 0 1-3.1-4.3v-8.53c0-13.908-11.318-25.224-25.228-25.224-13.91 0-25.23 11.316-25.23 25.224v8.133a4.516 4.516 0 0 1-3.152 4.318c-32.53 10.393-54.387 37.836-54.387 68.29 0 15.92 5.88 31.493 16.563 43.86 0.09 0.104 0.176 0.213 0.251 0.32 0.78 1.093 1.582 1.893 2.694 3l0.79 0.793c0.5 0.501 0.685 0.681 0.894 0.82 0.205 0.137 0.398 0.29 0.575 0.454 12.753 11.841 30.46 17.282 47.588 22.544l1.447 0.447c32.313 9.897 45.328 14.825 42.87 37.674-1.208 13.289-14.242 22.572-31.698 22.572-18.572 0-31.516-11.589-31.516-21.99 0-13.908-11.318-25.224-25.229-25.224-13.912 0-25.229 11.316-25.229 25.223 0 30.453 21.857 57.896 54.387 68.289a4.516 4.516 0 0 1 3.153 4.316v7.54c0 13.908 11.318 25.223 25.23 25.223z"
									p-id="12482" fill="#ffffff"></path>
							</svg>
							<div>FEDEX/UPS</div>
							<div><span>{{$t('i18nn_3b4e0cc90e264c6f')}}</span> <span>{{$t('i18nn_19918f72bcdca413')}}</span></div>
						</a>
					</li>
				</el-tooltip>
			</ul>
		</div>



		<div class="home-o-subtitle">{{$t('c2d9cc88561f8cdc')}}</div>
		<div class="home-o-list">
			<ul>
				<el-tooltip class="item" effect="dark" content="https://www.hytxlogistics.com/contact" placement="top">
					<li>
						<a href="https://www.hytxlogistics.com/contact" target="_blank"
							title="https://www.hytxlogistics.com/contact">
							<svg t="1652419648886" class="icon" viewBox="0 0 1024 1024" version="1.1"
								xmlns="http://www.w3.org/2000/svg" p-id="8588" width="200" height="200">
								<path
									d="M853.526528 64.525312 171.12576 64.525312c-58.799104 0-106.624 47.833088-106.624 106.624l0 682.394624c0 58.79808 47.82592 106.624 106.624 106.624l682.400768 0c58.800128 0 106.624-47.82592 106.624-106.624L960.150528 171.149312C960.150528 112.3584 912.326656 64.525312 853.526528 64.525312zM758.956032 481.431552c-5.835776 113.77664-55.287808 175.212544-146.962432 182.581248l-0.595968 0.064512-0.587776-0.016384c-23.5264-0.730112-41.306112-7.996416-52.849664-21.62176-5.900288-6.972416-10.07616-15.555584-12.491776-25.67168-22.731776 31.422464-57.114624 47.294464-102.683648 47.294464-47.588352-1.040384-104.43264-24.613888-106.268672-131.032064 7.322624-109.038592 59.578368-167.62368 155.33056-175.007744l0.580608-0.048128 0.579584 0.016384c38.644736 1.263616 61.32224 15.896576 74.574848 33.676288 0.684032-5.891072 1.263616-11.79136 1.75616-17.668096l0.818176-9.764864 51.176448 0-1.716224 12.147712c-13.7984 97.946624-22.192128 158.723072-24.918016 180.645888-6.897664 39.48032-0.960512 53.590016 3.185664 58.535936 1.833984 2.176 5.178368 5.07392 13.014016 5.07392 2.127872 0 4.381696-0.214016 6.788096-0.618496 54.498304-7.602176 83.439616-51.772416 88.77568-135.076864-5.510144-127.071232-75.37152-191.5392-213.542912-197.057536-141.530112 12.2368-215.622656 87.662592-226.509824 230.566912 6.87616 141.259776 81.723392 213.199872 228.819968 219.901952 80.811008-0.006144 146.168832-22.811648 193.794048-67.762176l8.687616-8.196096 25.392128 33.844224-7.018496 6.534144c-59.920384 55.805952-131.86048 84.10624-213.80608 84.10624-3.991552 0-8.00256-0.069632-12.036096-0.19968-177.620992-7.184384-271.261696-96.627712-278.423552-265.84576L211.796992 520.219648l0.047104-0.627712C224.699392 345.227264 319.65184 250.99776 494.055424 239.540224l0.516096-0.022528 0.539648 0.007168c166.439936 5.764096 255.205376 86.757376 263.845888 240.76288l0.031744 0.572416L758.956032 481.431552z"
									p-id="8589" fill="#ffffff"></path>
								<path
									d="M492.529664 406.368256c-65.683456 4.089856-98.866176 46.938112-101.439488 130.9696 0 73.952256 35.437568 82.526208 62.078976 82.526208 49.835008-5.0176 82.16064-38.4 97.7408-102.018048 2.492416-15.26272 3.833856-28.657664 3.833856-39.036928C555.966464 433.19296 536.180736 410.24512 492.529664 406.368256z"
									p-id="8590" fill="#ffffff"></path>
							</svg>
							<div>{{$t('i18nn_8861e53cbc04161f')}}</div>
							<div>E-Mail(US)</div>
						</a>
					</li>
				</el-tooltip>

				<el-tooltip class="item" effect="dark" content="626-989-9999" placement="top">
					<li>
						<a href="tel:626-989-9999" title="626-989-9999">
							<svg t="1652322771429" class="icon" viewBox="0 0 1024 1024" version="1.1"
								xmlns="http://www.w3.org/2000/svg" p-id="5944" width="200" height="200">
								<path
									d="M926.342144 849.004544c34.132992-59.40224 15.22688-105.025536-45.21984-150.674432-71.057408-53.664768-140.500992-89.761792-190.43328-31.939584 0 0-52.989952 63.081472-208.927744-84.535296-181.32992-172.8512-105.227264-234.155008-105.227264-234.155008 63.065088-63.401984 22.976512-110.700544-29.915136-182.334464-52.887552-71.632896-106.313728-94.30528-183.013376-32.697344C15.804416 251.394048 224.212992 530.164736 329.58464 638.56128l-0.004096 0.008192c0 0 160.234496 165.740544 261.066752 220.915712l53.941248 30.16192c77.366272 39.701504 164.267008 57.875456 225.446912 21.009408C870.035456 910.65856 899.526656 895.489024 926.342144 849.004544z"
									p-id="5945" fill="#ffffff"></path>
							</svg>
							<div>
								<span>{{$t('i18nn_b5e2fe92fe54ace2')}}</span>(US)
								<br />
								+1(626)989-9999
							</div>
						</a>
					</li>
				</el-tooltip>
				<!-- <el-tooltip class="item" effect="dark" content="626-989-9999" placement="top">
					<li>
						<a href="tel:626-989-9999" title="626-989-9999">
							<svg t="1652322771429" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5944" width="200" height="200">
								<path
									d="M926.342144 849.004544c34.132992-59.40224 15.22688-105.025536-45.21984-150.674432-71.057408-53.664768-140.500992-89.761792-190.43328-31.939584 0 0-52.989952 63.081472-208.927744-84.535296-181.32992-172.8512-105.227264-234.155008-105.227264-234.155008 63.065088-63.401984 22.976512-110.700544-29.915136-182.334464-52.887552-71.632896-106.313728-94.30528-183.013376-32.697344C15.804416 251.394048 224.212992 530.164736 329.58464 638.56128l-0.004096 0.008192c0 0 160.234496 165.740544 261.066752 220.915712l53.941248 30.16192c77.366272 39.701504 164.267008 57.875456 225.446912 21.009408C870.035456 910.65856 899.526656 895.489024 926.342144 849.004544z"
									p-id="5945"
									fill="#ffffff"
								></path>
							</svg>
							<div>
								<span>{{$t('i18nn_ab0778ba44a8f3c1')}}</span>(US)
								<br />
								+1 626-989-9999
							</div>
						</a>
					</li>
				</el-tooltip> -->
				<el-popover placement="top" width="210" trigger="hover">
					<div style="text-align: center; padding-bottom: 10px; font-weight: bold;">
						<img src="../../../assets/images/wechat_kf/wechat_hytx_wms.jpg" width="200" height="200" />
						<div><span>{{$t('4c6841ce9043c9fe')}}</span>(<span>{{$t('i18nn_e0a806b3165119e6')}}</span>)</div>
						<div>{{$t('i18nn_0f63cc67b9017a81')}}</div>
					</div>
					<li href="javascript:void(0)" title="8615972134233" slot="reference">
						<svg t="1652691507968" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
							p-id="3065" width="200" height="200">
							<path
								d="M693.12 347.264c11.776 0 23.36 0.896 35.008 2.176-31.36-146.048-187.456-254.528-365.696-254.528C163.2 94.912 0 230.656 0 403.136c0 99.52 54.272 181.248 145.024 244.736L108.8 756.864l126.72-63.488c45.312 8.896 81.664 18.112 126.912 18.112 11.392 0 22.656-0.512 33.792-1.344-7.04-24.256-11.2-49.6-11.2-76.032C385.088 475.776 521.024 347.264 693.12 347.264zM498.304 249.024c27.392 0 45.376 17.984 45.376 45.248 0 27.136-17.984 45.312-45.376 45.312-27.072 0-54.336-18.176-54.336-45.312C443.968 266.944 471.168 249.024 498.304 249.024zM244.672 339.584c-27.2 0-54.592-18.176-54.592-45.312 0-27.264 27.392-45.248 54.592-45.248S289.92 266.944 289.92 294.272C289.92 321.408 271.872 339.584 244.672 339.584zM1024 629.76c0-144.896-145.024-262.976-307.904-262.976-172.48 0-308.224 118.144-308.224 262.976 0 145.28 135.808 262.976 308.224 262.976 36.096 0 72.512-9.024 108.736-18.112l99.392 54.528-27.264-90.624C969.728 783.872 1024 711.488 1024 629.76zM616.128 584.384c-17.984 0-36.224-17.92-36.224-36.224 0-18.048 18.24-36.224 36.224-36.224 27.52 0 45.376 18.176 45.376 36.224C661.504 566.464 643.648 584.384 616.128 584.384zM815.488 584.384c-17.856 0-36.032-17.92-36.032-36.224 0-18.048 18.112-36.224 36.032-36.224 27.264 0 45.376 18.176 45.376 36.224C860.864 566.464 842.752 584.384 815.488 584.384z"
								p-id="3066" fill="#ffffff"></path>
						</svg>
						<div>{{$t('4c6841ce9043c9fe')}}<br />
							(<span>{{$t('i18nn_e0a806b3165119e6')}}</span>)
						</div>
					</li>
				</el-popover>
				<el-popover placement="top" width="210" trigger="hover">
					<div>
						<img src="../../../assets/images/wechat_kf/wechat_kf.jpg" width="200" height="200" />
						<div style="text-align: center; padding-bottom: 10px; font-weight: bold;">{{$t('i18nn_c12ab070fe3cbeac')}}
						</div>
						<div><span>{{$t('e82c9fff83ec2d91')}}</span>：+86 15972134233</div>
						<!-- <div>微信号：L245727336</div> -->
					</div>
					<li slot="reference">
						<a href="javascript:void(0)">
							<svg t="1652691507968" class="icon" viewBox="0 0 1024 1024" version="1.1"
								xmlns="http://www.w3.org/2000/svg" p-id="3065" width="200" height="200">
								<path
									d="M693.12 347.264c11.776 0 23.36 0.896 35.008 2.176-31.36-146.048-187.456-254.528-365.696-254.528C163.2 94.912 0 230.656 0 403.136c0 99.52 54.272 181.248 145.024 244.736L108.8 756.864l126.72-63.488c45.312 8.896 81.664 18.112 126.912 18.112 11.392 0 22.656-0.512 33.792-1.344-7.04-24.256-11.2-49.6-11.2-76.032C385.088 475.776 521.024 347.264 693.12 347.264zM498.304 249.024c27.392 0 45.376 17.984 45.376 45.248 0 27.136-17.984 45.312-45.376 45.312-27.072 0-54.336-18.176-54.336-45.312C443.968 266.944 471.168 249.024 498.304 249.024zM244.672 339.584c-27.2 0-54.592-18.176-54.592-45.312 0-27.264 27.392-45.248 54.592-45.248S289.92 266.944 289.92 294.272C289.92 321.408 271.872 339.584 244.672 339.584zM1024 629.76c0-144.896-145.024-262.976-307.904-262.976-172.48 0-308.224 118.144-308.224 262.976 0 145.28 135.808 262.976 308.224 262.976 36.096 0 72.512-9.024 108.736-18.112l99.392 54.528-27.264-90.624C969.728 783.872 1024 711.488 1024 629.76zM616.128 584.384c-17.984 0-36.224-17.92-36.224-36.224 0-18.048 18.24-36.224 36.224-36.224 27.52 0 45.376 18.176 45.376 36.224C661.504 566.464 643.648 584.384 616.128 584.384zM815.488 584.384c-17.856 0-36.032-17.92-36.032-36.224 0-18.048 18.112-36.224 36.032-36.224 27.264 0 45.376 18.176 45.376 36.224C860.864 566.464 842.752 584.384 815.488 584.384z"
									p-id="3066" fill="#ffffff"></path>
							</svg>
							<div>
								<span>{{$t('i18nn_b33f004fe453ef3b')}}</span>/<span>{{$t('i18nn_8292010ff4193c50')}}</span>(CN)
								<br />
								+86 15972134233
							</div>
						</a>
					</li>
				</el-popover>
				<el-popover placement="top" width="210" trigger="hover">
					<div>
						<img src="../../../assets/images/wechat_kf/wechat_sys.png" width="200" height="200" />
						<div style="text-align: center; padding-bottom: 10px; font-weight: bold;">{{$t('i18nn_c12ab070fe3cbeac')}}
						</div>
						<!-- <div>微信号：min615518756</div> -->
					</div>
					<li slot="reference">
						<a href="javascript:void(0)">
							<svg t="1652691507968" class="icon" viewBox="0 0 1024 1024" version="1.1"
								xmlns="http://www.w3.org/2000/svg" p-id="3065" width="200" height="200">
								<path
									d="M693.12 347.264c11.776 0 23.36 0.896 35.008 2.176-31.36-146.048-187.456-254.528-365.696-254.528C163.2 94.912 0 230.656 0 403.136c0 99.52 54.272 181.248 145.024 244.736L108.8 756.864l126.72-63.488c45.312 8.896 81.664 18.112 126.912 18.112 11.392 0 22.656-0.512 33.792-1.344-7.04-24.256-11.2-49.6-11.2-76.032C385.088 475.776 521.024 347.264 693.12 347.264zM498.304 249.024c27.392 0 45.376 17.984 45.376 45.248 0 27.136-17.984 45.312-45.376 45.312-27.072 0-54.336-18.176-54.336-45.312C443.968 266.944 471.168 249.024 498.304 249.024zM244.672 339.584c-27.2 0-54.592-18.176-54.592-45.312 0-27.264 27.392-45.248 54.592-45.248S289.92 266.944 289.92 294.272C289.92 321.408 271.872 339.584 244.672 339.584zM1024 629.76c0-144.896-145.024-262.976-307.904-262.976-172.48 0-308.224 118.144-308.224 262.976 0 145.28 135.808 262.976 308.224 262.976 36.096 0 72.512-9.024 108.736-18.112l99.392 54.528-27.264-90.624C969.728 783.872 1024 711.488 1024 629.76zM616.128 584.384c-17.984 0-36.224-17.92-36.224-36.224 0-18.048 18.24-36.224 36.224-36.224 27.52 0 45.376 18.176 45.376 36.224C661.504 566.464 643.648 584.384 616.128 584.384zM815.488 584.384c-17.856 0-36.032-17.92-36.032-36.224 0-18.048 18.112-36.224 36.032-36.224 27.264 0 45.376 18.176 45.376 36.224C860.864 566.464 842.752 584.384 815.488 584.384z"
									p-id="3066" fill="#ffffff"></path>
							</svg>
							<div>{{$t('i18nn_a4365f7edaab7453')}}<br />
								<span>{{$t('i18nn_8292010ff4193c50')}}</span>(CN)
							</div>
						</a>
					</li>
				</el-popover>
			</ul>
		</div>



		<div class="home-o-subtitle">系统网站</div>
		<div class="home-o-list">
			<ul>
				<el-tooltip class="item" effect="dark" content="https://www.hytxlogistics.com/" placement="top">
					<li>
						<a href="https://www.hytxlogistics.com/" target="_blank">
							<i style="font-size: 30px;" class="el-icon-link"></i>
							<div>{{$t('i18nn_411e2be4b5bc7080')}}</div>
						</a>
					</li>
				</el-tooltip>

				<el-tooltip class="item" effect="dark" content="https://admin.warehouse.hytx.com/" placement="top">
					<li>
						<a href="https://admin.warehouse.hytx.com/" target="_blank">
							<i style="font-size: 30px;" class="el-icon-link"></i>
							<div>互易仓管理端(CN<span>{{$t('i18nn_f5924c3c9ec8fb0b')}}</span>)</div>
						</a>
					</li>
				</el-tooltip>
				<el-tooltip class="item" effect="dark" content="https://usadmin.warehouse.hytx.com/" placement="top">
					<li>
						<a href="https://usadmin.warehouse.hytx.com/" target="_blank">
							<i style="font-size: 30px;" class="el-icon-link"></i>
							<div>互易仓管理端(US<span>{{$t('i18nn_f5924c3c9ec8fb0b')}}</span>)</div>
						</a>
					</li>
				</el-tooltip>

				<el-tooltip class="item" effect="dark" content="https://warehouse.hytx.com/" placement="top">
					<li>
						<a href="https://warehouse.hytx.com/" target="_blank">
							<i style="font-size: 30px;" class="el-icon-link"></i>
							<div>互易仓客户端(CN线路)</div>
						</a>
					</li>
				</el-tooltip>
				<el-tooltip class="item" effect="dark" content="https://uswarehouse.hytx.com/" placement="top">
					<li>
						<a href="https://uswarehouse.hytx.com/" target="_blank">
							<i style="font-size: 30px;" class="el-icon-link"></i>
							<div>互易仓客户端(US线路)</div>
						</a>
					</li>
				</el-tooltip>

				<el-tooltip class="item" effect="dark" content="https://appwms.hytx.com/" placement="top">
					<li>
						<a href="https://appwms.hytx.com/" target="_blank">
							<i style="font-size: 30px;" class="el-icon-link"></i>
							<div>互易仓PDA端(CN线路)</div>
						</a>
					</li>
				</el-tooltip>
				<el-tooltip class="item" effect="dark" content="https://usappwms.hytx.com/" placement="top">
					<li>
						<a href="https://usappwms.hytx.com/" target="_blank">
							<i style="font-size: 30px;" class="el-icon-link"></i>
							<div>互易仓PDA端(US线路)</div>
						</a>
					</li>
				</el-tooltip>
			</ul>
		</div>
		<div class="home-o-subtitle">{{$t('i18nn_e65143a1620ea3df')}}</div>
		<div class="home-o-list">
			<ul>
				<!-- <el-tooltip class="item" effect="dark" :content="$t('i18nn_960d7b5c5217102c')" placement="top"> -->
				<li>
					<!-- <a
							href="https://usappwms.hytx.com/"
							target="_blank">
							<i style="font-size: 30px;" class="el-icon-link"></i>
							<div>{{$t('i18nn_960d7b5c5217102c')}}</div>
						</a> -->
					<router-link :to="{ name: 'mapAddressDistance'}">
						<i style="font-size: 30px;" class="el-icon-link"></i>
						<div>{{$t('i18nn_960d7b5c5217102c')}}</div>
					</router-link>
				</li>
				<!-- </el-tooltip> -->

				<!-- <el-tooltip class="item" effect="dark" :content="'日历'" placement="top"> -->
				<li>
					<router-link :to="{ name: 'WhCalendar'}">
						<i style="font-size: 30px;" class="el-icon-link"></i>
						<div>日历</div>
					</router-link>
				</li>
				<!-- </el-tooltip> -->
			</ul>
		</div>

	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				// linkList:[
				// 	{
				// 		title:this.$t('i18nn_c0eb5cb77d8024b6'),
				// 		links:[
				// 			{
				// 				link:'https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/helpDoc/admin/HYTX_WMS_Admin_HELP_CN.V5.pdf',
				// 				linkName:'帮助文档(中文)',
				// 				linkPic:''
				// 			}
				// 		]
				// 	},
				// 	{
				// 		title:"报价/询价/提货",
				// 		links:[
				// 			{
				// 				link:'https://docs.qq.com/sheet/DWWJTeE5RTkp4ZlpN',
				// 				linkName:this.$t('i18nn_b5f81f005ec90ee8'),
				// 				linkPic:''
				// 			},
				// 			{
				// 				link:'https://docs.qq.com/form/page/DWVF0dVVtaEVJVnpY#/fill',
				// 				linkName:this.$t('i18nn_d8f295864833fc57'),
				// 				linkPic:''
				// 			},
				// 			{
				// 				link:'https://docs.qq.com/form/page/DWUxnanR5RnRRZXZV#/fill',
				// 				linkName:'提货/空柜预约(FedEx/UPS)',
				// 				linkPic:''
				// 			},
				// 		]
				// 	},
				// 	{
				// 		title:this.$t('c2d9cc88561f8cdc'),
				// 		links:[
				// 			{
				// 				link:'https://www.hytxlogistics.com/contact',
				// 				linkName:'海外仓email(US)',
				// 				linkPic:''
				// 			},
				// 			{
				// 				link:'+1(909)989-9999',
				// 				linkName:'总部电话(US)+1(909)989-9999',
				// 				linkPic:''
				// 			},
				// 			{
				// 				link:'+1 626-989-9999',
				// 				linkName:'卡车部电话(US)+1 626-989-9999',
				// 				linkPic:''
				// 			},
				// 			{
				// 				link:'+86 15972134233',
				// 				linkName:'客服手机/微信(CN)+86 15972134233',
				// 				linkPic:''
				// 			},

				// 		]
				// 	}
				// ]
			};
		},
		activated() {},
		mounted() {},
		methods: {}
	};
</script>

<style scoped lang="less">
	@import url(../../../assets/css/home.less);
</style>